import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';

/* style sheet */
import { Imgover } from '../style/default';
import Loading from '../../plugin/Loading';

// naver store api
//import { NaverstoreApi } from '../../../data/naverstore-api';

const NaverContainer = styled(Container)`
  a {
    font-size: 14px;
    text-transform: uppercase;
    padding: 20px;

    font-weight: 400;
    color: #111;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1rem;
      text-align: center;
    }

    &:hover {
      color: #111;
    }
  }
`;

export default function NaverStoreContainer() {
  const [slideID, setslideID] = useState(0);
  const [items, setItems] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const SliderGitavApi = async () => {
    try {
      setError(null);
      setItems(null);
      setLoading(true);
      const { data: data } = await axios.get('/api/onlinemarket');
      setItems(data.list);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    SliderGitavApi();
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!items) return null;
  return (
    <NaverContainer className="my-5">
      <Row>
        <Col xs={12}>
          <h4 style={{ fontFamily: 'GmarketSansMedium', fontWeight: '600' }}>택배주문하기</h4>
        </Col>
      </Row>
      <Row>
        {items.map((data) => {
          return (
            <Col key={data.om_id} xs={12} sm={12} md={6} lg={4} className="mb-4">
              <a href={data.om_link} target="_blank" rel="noreferrer">
                <Card>
                  <div style={{ width: '100%', overflow: 'hidden' }}>
                    <Imgover variant="center" src={data.om_filename} />
                  </div>
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'GmarketSansMedium' }}>
                      {data.om_title}
                    </Card.Title>
                    <Card.Text style={{ fontFamily: 'GmarketSansLight' }}>
                      <span
                        style={{
                          display: 'block',
                          textDecoration: 'line-through',
                          color: '#999',
                          letterSpacing: '0.5px',
                          marginBottom: '5px',
                        }}
                      >
                        <CurrencyFormat
                          value={data.om_amount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'₩'}
                        />
                      </span>
                      <span
                        style={{
                          fontFamily: 'GmarketSansMedium',
                          fontSize: '20px',
                          fontWeight: '700',
                          letterSpacing: '0.5px',
                          marginRight: '10px',
                          color: '#E73112',
                        }}
                      >
                        <CurrencyFormat
                          value={data.om_saleper}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={''}
                        />
                      </span>
                      <span
                        style={{
                          fontFamily: 'GmarketSansMedium',
                          fontSize: '20px',
                          fontWeight: '700',
                          letterSpacing: '0.5px',
                        }}
                      >
                        <CurrencyFormat
                          value={data.om_sale}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'₩'}
                        />
                      </span>
                    </Card.Text>
                    <Card.Text style={{ fontFamily: 'GmarketSansLight' }}>
                      {data.om_summary}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          );
        })}
      </Row>
    </NaverContainer>
  );
}
