import React from "react";
import { bool, func } from "prop-types";
import { Hamberger } from "./style/header";

const Burgerbox = ({ open, setOpen, sideopen, setSideopen, }) => {
  return (
    <Hamberger
      open={open}
      onClick={() => {
        setOpen(!open);
        setSideopen(!sideopen);
      }}
    >
      <div></div>
      <div></div>
      <div></div>
    </Hamberger>
  );
};

Burgerbox.propTypes = {
  open: bool,
  sideopen: bool,
  setOpen: func,
  setSideopen: func,
};

export default Burgerbox;
