import React from "react";
import { bool, func } from "prop-types";
import { MenuApi } from "../../data/menu-api";
import { SideMenu, SubMenuBackdrop } from "./style/header";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sidemenu = ({ open, setOpen, sideopen, setSideopen }) => {
  return (
    <>
      <Container fluid className="d-xl-none d-lg-none  d-block">
        <Row>
          <Col xs="12">
            <SideMenu
              open={open}
              setOpen={setOpen}
              sideopen={sideopen}
              setSideopen={setSideopen}
            >
              <ul>
                {MenuApi.map((menu) => {
                  return (
                    <li key={menu.id} className="sidegnb">
                      <Link
                        to={menu.link}
                        onClick={() => {
                          setOpen(!open);
                          setSideopen(!sideopen);
                        }}
                      >
                        {menu.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </SideMenu>
          </Col>
        </Row>
      </Container>

      {sideopen ? (
        <SubMenuBackdrop className="d-xl-none d-lg-none  d-block" />
      ) : (
        ""
      )}
    </>
  );
};

export default Sidemenu;

Sidemenu.propTypes = {
  open: bool,
  sideopen: bool,
  setOpen: func,
  setSideopen: func,
};
