export const MenuApi = [
  {
    id: "0",
    title: "화라락 소개",
    link: "/about",
    submenux: "160",
  },
  {
    id: "1",
    title: "메뉴안내",
    link: "/menu",
  },
  {
    id: "2",
    title: "오시는길",
    link: "/contact",
    submenux: "480",
  },
  {
    id: "3",
    title: "커뮤니티",
    link: "/board/notice",
    submenux: "740",
  },
];
