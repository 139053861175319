import React, { useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';

const SearchForm = (props) => {
  const { sfield, setSfield, skeyword, setSkeyword } = props;

  const sfiledArray = [
    { sfield: 'post_title', sfieldtext: '제목' },
    { sfield: 'post_content', sfieldtext: '내용' },
    { sfield: 'post_both', sfieldtext: '제목+내용' },
  ];

  const [select, setSelect] = useState(sfield ? sfield : null);
  const [inputtext, setInputtext] = useState(skeyword ? skeyword : null);
  const handleSelectChange = (event) => {
    setSelect(event.target.value);
  };
  const handleInputChange = (event) => {
    setInputtext(event.target.value);
  };
  const clickHandler = (e) => {
    e.preventDefault();
    if (inputtext !== null) {
      setSfield(select);
      setSkeyword(inputtext);
    }
  };
  return (
    <Form>
      <InputGroup className="mb-3">
        <Form.Control
          as="select"
          name="sfield"
          value={select}
          custom
          style={{ fontSize: '13px' }}
          value={`${sfield !== null ? sfield : ''}`}
          onChange={handleSelectChange}
        >
          {sfiledArray.map((e, i) => {
            return (
              <option key={i} value={e.sfield}>
                {e.sfieldtext}
              </option>
            );
          })}
        </Form.Control>
        <FormControl
          placeholder="검색어를 입력하세요."
          aria-label="검색어를 입력하세요."
          aria-describedby="basic-addon2"
          name="skeyword"
          style={{ fontSize: '13px', width: '200px' }}
          value={`${inputtext !== null ? inputtext : ''}`}
          onChange={handleInputChange}
        />
        <InputGroup.Append>
          <Button variant="outline-secondary" style={{ fontSize: '13px' }} onClick={clickHandler}>
            검색
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

export default SearchForm;
