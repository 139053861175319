/* global kakao */

import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

export const InfoTitle = styled.div`
  span {
    display: block;
    font-size: 12px;
    letter-spacing: -0.45px;
    line-height: 1.5;
    color: #929292;
    padding-top: 5px;
  }
  .title {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.46px;
    line-height: 22px;
    color: #2c2c2c;
    margin: 10px 0 8px 35px;
  }
  .content {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.8px;
    line-height: 1.46;
    color: #2c2c2c;
    margin-left: 29px;
    margin-bottom: 10px;
    a {
      color: #2c2c2c;
      text-decoration: none;
    }
    a:last-child {
      margin-left: 10px;
    }
  }
`;

export default function KakaoMap() {
  const [kakaoMap, setKakaoMap] = useState(null);

  const container = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://dapi.kakao.com/v2/maps/sdk.js?appkey=d0f33e6f366e34650c19fb8f33895929&autoload=false";
    document.head.appendChild(script);

    script.onload = () => {
      kakao.maps.load(() => {
        const center = new kakao.maps.LatLng(
          36.6119240065059,
          127.466007254556
        );
        const options = {
          center,
          level: 3,
        };
        const map = new kakao.maps.Map(container.current, options);
        //setMapCenter(center);
        setKakaoMap(map);
      });
    };
  }, [container]);

  useEffect(() => {
    if (kakaoMap === null) {
      return;
    }

    // save center position
    const center = kakaoMap.getCenter();

    // change viewport size
    container.current.style.width = "100%";
    container.current.style.height = "400px";

    // relayout and...
    kakaoMap.relayout();
    // restore
    kakaoMap.setCenter(center);
  }, [kakaoMap]);

  useEffect(() => {
    if (kakaoMap === null) {
      return;
    }

    const markerPosition = new kakao.maps.LatLng(
      36.6119240065059,
      127.466007254556
    );

    const marker = new kakao.maps.Marker({ position: markerPosition });
    marker.setMap(kakaoMap);

    var iwContent =
        '<div class="title">화라락 양꼬치</div><div class="content"><a href="https://map.kakao.com/link/map/화라락양꼬치,36.6119240065059, 127.466007254556" style="color:blue" target="_blank">큰지도보기</a> <a href="https://map.kakao.com/link/to/화라락양꼬치,36.6119240065059,127.466007254556" style="color:blue" target="_blank">길찾기</a></div>',
      iwPosition = new kakao.maps.LatLng(36.6119240065059, 127.466007254556); //인포윈도우 표시 위치입니다

    // 인포윈도우를 생성합니다
    var infowindow = new kakao.maps.InfoWindow({
      position: iwPosition,
      content: iwContent,
    });

    // 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
    infowindow.open(kakaoMap, marker);
  }, [kakaoMap]);

  return <InfoTitle id="container" ref={container} />;
}
