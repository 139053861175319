import styled from "styled-components";
import { Container, Col } from "react-bootstrap";

export const Warp = styled(Container)`
  border-top: 1px solid #eaeaea;
  padding: 32px 0 35px;
  font-family: "Noto Sans KR";
`;

export const Copyright = styled(Col)`
  p {
    font-size: 12px;
    color: #8e929f;
    position: relative;
    padding: 5px 10px;
    margin: 0;
  }
`;

export const Summry = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  ul {
    box-sizing: border-box;
    list-style: none;
    padding: 0 10px;
  }
  ul:last-child {
    margin-left: 50px;
    @media (max-width: 576px) {
      margin-left: 10px;
    }
  }
  li > span {
    display: block;
    font-size: 12px;
    letter-spacing: -0.45px;
    line-height: 1.5;
    color: #929292;
    padding-top: 5px;
  }
  .title {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.46px;
    line-height: 22px;
    color: #2c2c2c;
    margin: 0 0 8px;
  }
  .content {
    font-weight: 700;
    font-size: 26px;
    letter-spacing: -0.8px;
    line-height: 1.46;
    color: #2c2c2c;
    a {
      color: #2c2c2c;
      text-decoration: none;
    }
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
`;

export const Address = styled.div`
  span {
    font-size: 12px;
    color: #929292;
    position: relative;
    padding: 0 10px 0;
  }
  span:first-child {
    padding: 0 10px 0 0;
  }
  span:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 1px;
    height: 10px;
    background-color: #8e929f;
    opacity: 0.5;
  }
  .brandname {
    font-weight: 500;
    font-size: 16px;
    color: #2c2c2c;
  }
`;

/*
.footer .footer-inner .info .address span:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 2px;
    right: 0;
    width: 1px;
    height: 10px;
    background-color: #8e929f;
    opacity: 0.5;
}
*/
