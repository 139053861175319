import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

/* style sheet */
import { Imgover } from '../style/default';

// slideApi
import { SpecialApi } from '../../../data/special-api';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function SpecialContainer() {
  return (
    <Container className="my-5">
      <Row>
        <Col xs={12}>
          <h4 style={{ fontFamily: 'GmarketSansMedium', fontWeight: '600' }}>스폐셜 메뉴</h4>
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <Swiper
            spaceBetween={20}
            slidesPerView="auto"
            loop
            //navigation
            //pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              992: { slidesPerView: 3 },
              768: { slidesPerView: 2 },
              576: { slidesPerView: 1, spaceBetween: 20 },
            }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => {}}
          >
            {SpecialApi.map((data) => {
              return (
                <SwiperSlide key={data.id}>
                  <Card>
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                      <Imgover variant="center" src={data.image} />
                    </div>
                    <Card.Body>
                      <Card.Title style={{ fontFamily: 'GmarketSansMedium' }}>
                        {data.title}
                      </Card.Title>
                      <Card.Text style={{ fontFamily: 'GmarketSansLight' }}>
                        {data.summary}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Col>
      </Row>
    </Container>
  );
}
