import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

/* style sheet */
import { Warp } from '../style/default';

import List from './List';
import View from './View';

const Board = () => {
  const [viewOpen, seViewOpen] = useState(false);
  const [id, setId] = useState(0);
  return (
    <div style={{ minHeight: '300px' }}>
      <Warp>
        <Row>
          <Col className="mt-5 mb-3" style={{ fontFamily: 'GmarketSansMedium', fontWeight: '600' }}>
            <h3>커뮤니티</h3>
          </Col>
        </Row>
      </Warp>
      {viewOpen ? (
        <View viewOpen={viewOpen} seViewOpen={seViewOpen} id={id} />
      ) : (
        <List viewOpen={viewOpen} seViewOpen={seViewOpen} setId={setId} />
      )}
    </div>
  );
};

export default Board;
