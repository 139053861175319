import styled from "styled-components";
import { Container, Col } from "react-bootstrap";

export const Warp_back = styled(Col)`
  height: 100px;
  @media (max-width: 991.98px) {
    height: 60px;
  }
`;

export const Warp = styled(Container)`
  top: 0;
  position: fixed;
  width: 100%;
  display: flex;
  flex: top;
  border-bottom: 1px solid #d7dbe6;
  background: #fff;
  z-index: 200;
`;

export const Logo = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 100px;
  img {
    height: 60px;
  }
  @media (max-width: 991.98px) {
    height: 60px;
    img {
      height: 50px;
    }
    justify-content: left;
  }
  @media (max-width: 787.98px) {
    height: 60px;
    img {
      height: 40px;
    }
    justify-content: left;
  }
  @media (max-width: 576px) {
    height: 60px;
    img {
      height: 40px;
    }
    justify-content: left;
  }
`;

// 햄버거메뉴
export const HambergerBox = styled(Col)`
  display: flex;
  align-items: center;
`;

export const Hamberger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: auto;
  padding: 0;
  width: 33px;
  height: 33px;
  flex-flow: column nowrap;

  @media (max-width: 991.98px) {
    margin-top: 5px;
  }

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    height: 3px;
    background-color: #111;
    border-radius: 1.5px;
    transition: all 0.2s ease-in-out;
    transform-origin: 0.5px;

    :nth-child(1) {
      transform: ${({ open }) =>
        open
          ? "rotate(45deg) translateX(2px) translateY(-2px)"
          : "rotate(0)  translateX(0) translateY(0)"};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) =>
        open ? "translateX(-10px)" : "translateX(0)"};
    }
    :nth-child(3) {
      transform: ${({ open }) =>
        open
          ? "rotate(-45deg) translateX(2px) translateY(2px)"
          : "rotate(0) translateX(0) translateY(0)"};
    }
  }
`;

//서브메뉴

// 서브메뉴
export const SubMenu = styled(Container)`
  transition: all 0.5s ease-in-out;
  transform-origin: 0.5px;
  top: 100px;
  position: fixed;
  background-color: #e73112;
  height: 60px;
  top: 101px;
  z-index: 100;
  font-family: GmarketSansLight;
`;

export const SubMenuCenter = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
`;

export const SubMenuLink = styled(Col)`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  div {
    display: flex;
    list-style: none;
    padding: 0 30px;
    margin: 0;
  }

  @media (max-width: 1199.98px) {
    div {
      display: flex;
      list-style: none;
      float: left;
      padding: 0 10px;
    }
  }

  a {
    font-size: 14px;
    text-transform: uppercase;
    padding: 20px;

    font-weight: 400;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1rem;
      text-align: center;
    }

    &:hover {
      color: #ccc;
    }
  }
`;

// 사이드메뉴
export const SideMenu = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: top;
  background: #effffa;
  opacity: 1;
  z-index: 101;
  top: 101px;
  width: 500px;
  height: 100vh;
  right: 0;
  transition: all 0.2s ease-in-out;
  transform: ${({ sideopen }) =>
    sideopen ? "translateX(0)" : "translateX(100%)"};
  font-family: GmarketSansMedium;

  ul {
    padding: 0;
  }
  li.sidegnb {
    list-style: none;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #ccc;
    a {
      display: flex;
      font-size: 14px;
      text-transform: uppercase;
      padding: 15px 0 15px 10px;

      font-weight: 600;
      color: #0d0c1d;
      text-decoration: none;
      transition: color 0.2s linear;

      @media (max-width: 576px) {
        font-size: 14px;
        text-align: center;
      }

      &:hover {
        color: #343078;
      }
    }
  }

  div {
    width: 100%;
  }
  div ul {
    width: 100%;
  }

  div ul li:first-child {
    border-top: 1px solid #ccc;
  }

  div ul li.sidesnb {
    list-style: none;
    padding: 0 20px;
    width: 100%;
    border-bottom: 1px solid #ccc;

    a {
      font-size: 12px;
      text-transform: uppercase;

      font-weight: 400;
      color: #0d0c1d;
      text-decoration: none;
      transition: color 0.3s linear;

      @media (max-width: 576px) {
        font-size: 12px;
        text-align: center;
      }

      &:hover {
        color: #343078;
      }
    }
  }

  div ul li:last-child {
    border: none;
    padding: 0 20px 0 20px;
  }

  @media (max-width: 991.98px) {
    top: 61px;
  }

  @media (max-width: 576px) {
    width: 100%;
    left: 0;
    transform: ${({ sideopen }) =>
      sideopen ? "translateX(0)" : "translateX(100%)"};
  }
`;

export const SubMenuBackdrop = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #111;
  opacity: 0.9;
  z-index: 100;
`;
