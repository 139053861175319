export const SpecialApi = [
  {
    id: '0',
    title: '양다리 초벌구이',
    summary:
      '화라락에서는 양다리에 염지를 하여, 숙성을 시킨 후, 중국에서 직접 수입한, 대형 양다리 구이 오븐에 초벌구이를 하여 더욱 맛있게 제공을 해드리고 있습니다.',
    tag: '#화라락 #양다리 #초벌구이 #완전맛있음',
    image: '/images/main/simg02.jpg',
    color: '#fff',
    backgroundcolor: 'rgb(44, 44, 44)',
  },
  {
    id: '1',
    title: '정통 마라 우육면',
    summary:
      '화라락에서는 양다리에 염지를 하여, 숙성을 시킨 후, 중국에서 직접 수입한, 대형 양다리 구이 오븐에 초벌구이를 하여 더욱 맛있게 제공을 해드리고 있습니다.',
    tag: '#화라락 #마라우육면 #대만',
    image: '/images/main/simg03.jpg',
    color: '#fff',
    backgroundcolor: '#8e0009',
  },
  {
    id: '2',
    title: '양갈비 초벌구이',
    summary:
      '화라락에서는 양다리에 염지를 하여, 숙성을 시킨 후, 중국에서 직접 수입한, 대형 양다리 구이 오븐에 초벌구이를 하여 더욱 맛있게 제공을 해드리고 있습니다.',
    tag: '#화라락 #양갈비 #초벌구이',
    image: '/images/main/simg01.jpg',
    color: '#fff',
    backgroundcolor: 'rgb(44, 44, 44)',
  },
];
