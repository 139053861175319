import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Burgerbox from "./Buggerbox";
import Submenu from "./Submenu";
import SideMenu from "./Sidemenu";
import { Warp, Warp_back, Logo, HambergerBox } from "./style/header";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [sideopen, setSideopen] = useState(false);
  return (
    <>
      <Warp_back></Warp_back>
      <Warp fluid>
        <Container>
          <Row>
            <Col lg={4} md={4} className="d-xl-block d-lg-block d-none"></Col>
            <Logo lg={4} md={6} sm={6} xs={6}>
              <Link
                to="/"
                onClick={() => {
                  if (open === true) setOpen(false);
                  if (sideopen === true) setSideopen(false);
                }}
              >
                <img src="/images/common/logo.svg" />
              </Link>
            </Logo>
            <HambergerBox lg={4} md={6} sm={6} xs={6}>
              <Burgerbox
                open={open}
                setOpen={setOpen}
                sideopen={sideopen}
                setSideopen={setSideopen}
              />
            </HambergerBox>
          </Row>
        </Container>
      </Warp>
      <Submenu open={open} setOpen={setOpen} />
      <SideMenu
        open={open}
        setOpen={setOpen}
        sideopen={sideopen}
        setSideopen={setSideopen}
      />
    </>
  );
};

export default Header;
