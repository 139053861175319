import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Warp, Copyright, Address, Summry } from './style/footer';

Kakao.init('d0f33e6f366e34650c19fb8f33895929');

function sendLink() {
  // 카카오톡 공유하기
  Kakao.Link.sendDefault({
    objectType: 'feed',
    content: {
      title: document.title,
      description:
        '화라락 양꼬치는 2013년 오픈을 했으며, 특별한 양념으로 고기를 재워 양특유의 향을 잡아 한국인들의 입맛에 맞추었습니다. 언제나 변함없이 질좋은 양고기와 맛있는 중국 요리를 제공하기 위하여 꾸준히 노력 중입니다.',
      imageUrl: 'https://www.hwararak.com/images/common/logo.svg',
      link: {
        mobileWebUrl: 'https://www.hwararak.com/',
        webUrl: 'https://www.hwararak.com/',
      },
    },
    buttons: [
      {
        title: '전화문의',
        link: {
          mobileWebUrl: 'tel:043-274-6177',
          webUrl: 'tel:043-274-6177',
        },
      },
    ],
  });
}

const Footer = () => {
  return (
    <Warp fluid>
      <Container>
        <Row>
          <Copyright className="p-1">
            <Summry>
              <ul>
                <li className="title">문의 및 예약</li>
                <li className="content">
                  <a href="tel:043-274-6177">043-274-6177</a>
                </li>
                <li>
                  <span>hwararak@gmail.com</span>
                </li>
              </ul>
              <ul>
                <li className="title">매장운영시간</li>
                <li className="content">11:00 ~ 23:00</li>
                <li>
                  <span>첫째, 셋째 일요일 휴무</span>
                </li>
              </ul>
            </Summry>
          </Copyright>
        </Row>
        <Row>
          <Copyright>
            <Address style={{ marginBottom: '20px' }}>
              <span className="brandname">화라락 양꼬치</span>
            </Address>
            <Address>
              <span>대표: 김민나</span>
              <span>사업자등록번호: 254-47-00112</span>
            </Address>
            <Address>
              <span>충북 청주시 서원구 산남로70번길 17 2F (우편 28625)</span>
            </Address>
            <p className="px-0 py-3">Copyright hwararak. All Rights Reserved.</p>
            <a href="https://www.facebook.com/hwararaklove" target="_blank" rel="noreferrer">
              <img src="/images/common/facebook.svg" style={{ width: '32px' }} />
            </a>
            <a
              href="https://www.instagram.com/hwararak/"
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '10px' }}
            >
              <img src="/images/common/instagram.svg" style={{ width: '30px' }} />
            </a>

            <a href="#" onClick={sendLink} style={{ marginLeft: '10px' }}>
              <img src="/images/common/kakaotalk.svg" style={{ width: '30px' }} />
            </a>
          </Copyright>
        </Row>
      </Container>
    </Warp>
  );
};

export default Footer;
