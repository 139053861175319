import React from "react";
import { bool, func } from "prop-types";
import { Link } from "react-router-dom";
import { SubMenu, SubMenuCenter, SubMenuLink } from "./style/header";
import { MenuApi } from "../../data/menu-api";

const Submenu = ({ open, setOpen }) => {
  return open ? (
    <SubMenu
      fluid
      open={open}
      onMouseLeave={(e) => {
        e.preventDefault();
        setOpen(!open);
      }}
      className="d-xl-block d-lg-block d-none"
    >
      <SubMenuCenter>
        <SubMenuLink>
          {MenuApi.map((menu) => {
            return (
              <div key={menu.id}>
                <Link
                  to={menu.link}
                  onClick={() => {
                    console.log(menu.link);
                    setOpen(!open);
                  }}
                >
                  {menu.title}
                </Link>
              </div>
            );
          })}
        </SubMenuLink>
      </SubMenuCenter>
    </SubMenu>
  ) : (
    ""
  );
};

Submenu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Submenu;
