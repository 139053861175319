import React from "react";
/*
import { Container, Card, Row, Col } from "react-bootstrap";
*/
/* style sheet */
import { Warp } from "../style/default";

/* Home 컨테이너 연결 */
import SpecialContainer from "./SpecialContainer";
import NaverStoreContainer from "./NaverStoreContainer";

/* 플러그인 호출 */
import MainSlider from "../../plugin/MainSlider";
//import Instagram from "../../plugin/instagram/Instagram";

// slideApi
//import { SpecialApi } from "../../../data/special-api";

export default function Home() {
  return (
    <Warp fluid className="p-0 m-0">
      <MainSlider height={370} slidesPerView="auto" />
      <SpecialContainer />
      <NaverStoreContainer />
      {/*
      <Container className="my-5">
        <Row>
          <Col xs={12}>
            <h4 style={{ fontFamily: "GmarketSansMedium", fontWeight: "600" }}>
              인스타그램 #화라락양꼬치
            </h4>
          </Col>
        </Row>
        <Row>
          {SpecialApi.map((data) => {
            return (
              <Col key={data.id} xs={12} sm={12} md={6} lg={4} className="mb-4">
                <Card>
                  <div style={{ width: "100%", overflow: "hidden" }}>
                    <Imgover
                      variant="center"
                      src={process.env.PUBLIC_URL + data.pc}
                    />
                  </div>
                  <Card.Body>
                    <Card.Title style={{ fontFamily: "GmarketSansMedium" }}>
                      {data.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
        */}
    </Warp>
  );
}
