import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/* 레이아웃 호출 */
import { Header } from './components/header';
import { Footer } from './components/footer';

/* 섹션 호출 */
import Home from './components/section/home/Home';
import About from './components/section/about/About';
import Contact from './components/section/contact/Contact';
import Menu from './components/section/menu/Menu';
import Board from './components/section/board/Board';
import View from './components/section/board/View';

//console.log(csrf_token_name);

const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/menu" component={Menu} />
        <Route path="/contact" component={Contact} />
        <Route path="/board/view/:table/:id" component={View} />
        <Route path="/board/:table" component={Board} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
