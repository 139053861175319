import React from "react";
import { Container, Row, Col } from "react-bootstrap";
/* style sheet */
import { Warp } from "../style/default";
// AboutslideApi

import Slider from "./Slider";

export default function About() {
  return (
    <>
      <Warp>
        <Row>
          <Col
            className="mt-5 mb-3"
            style={{ fontFamily: "GmarketSansMedium", fontWeight: "600" }}
          >
            <h3>화라락 소개</h3>
          </Col>
        </Row>
      </Warp>

      <Container className="mb-5">
        <Row>
          <Col md={6} style={{ marginBottom: "20px" }}>
            <Slider />
          </Col>
          <Col
            md={6}
            style={{
              fontSize: "15px",
              letterSpacing: "-0.45px",
              lineHeight: "1.5",
              color: "#111",
              paddingTop: "5px",
              textAlign: "justify",
            }}
          >
            <h5 style={{ lineHeight: "1.5" }}>
              2013년 오픈한 화라락! <br />
              맛있고 즐거움이 있는 화라락으로 만들고자 합니다.
            </h5>
            <p
              style={{
                color: "#929292",
                marginTop: "20px",
              }}
            >
              화라락 양꼬치는 2013년 오픈을 했으며, 특별한 양념으로 고기를 재워
              양특유의 향을 잡아 한국인들의 입맛에 맞추었습니다. 언제나 변함없이
              질좋은 양고기와 맛있는 중국 요리를 제공하기 위하여 꾸준히 노력
              중입니다.
            </p>

            <p
              style={{
                color: "#111",
                marginTop: "20px",
              }}
            >
              화라락(火辣樂)의 뜻은 불맛과 매운맛과 즐거움이 가득하다는 뜻으로,
              언제나 즐거움이 가득한 곳으로 만들기 위해 노력하겠습니다.
            </p>

            <p
              style={{
                color: "#929292",
                marginTop: "20px",
              }}
            >
              감사합니다.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
