export const AboutSliderApi = [
  {
    id: '0',
    title: '화라락에서 야심차게 준비한',
    summary: '양다리 초벌구이',
    tag: '#화라락 #양다리 #초벌구이 #완전맛있음',
    image: '/images/about/1.jpg',
    color: '#fff',
    backgroundcolor: 'rgb(44, 44, 44)',
  },
  {
    id: '1',
    title: '화라락에서 대만을 즐긴다',
    summary: '정통 마라 우육면',
    tag: '#화라락 #마라우육면 #대만',
    image: '/images/about/2.jpg',
    color: '#fff',
    backgroundcolor: '#8e0009',
  },
  {
    id: '2',
    title: '화라락이 아니면 맛볼수 있을까?',
    summary: '양갈비 초벌구이',
    tag: '#화라락 #양갈비 #초벌구이',
    image: '/images/about/3.jpg',
    color: '#fff',
    backgroundcolor: 'rgb(44, 44, 44)',
  },
  {
    id: '3',
    title: '화라락이 아니면 맛볼수 있을까?',
    summary: '양갈비 초벌구이',
    tag: '#화라락 #양갈비 #초벌구이',
    image: '/images/about/4.jpg',
    color: '#fff',
    backgroundcolor: 'rgb(44, 44, 44)',
  },
  {
    id: '4',
    title: '화라락이 아니면 맛볼수 있을까?',
    summary: '양갈비 초벌구이',
    tag: '#화라락 #양갈비 #초벌구이',
    image: '/images/about/5.jpg',
    color: '#fff',
    backgroundcolor: 'rgb(44, 44, 44)',
  },
];
