import React from 'react';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// slideApi
import { AboutSliderApi } from '../../../data/about-slide-api';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function Slider() {
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView="auto"
      loop
      //navigation
      //pagination={{ clickable: true }}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => {}}
    >
      {AboutSliderApi.map((data) => {
        return (
          <SwiperSlide key={data.id}>
            <img src={data.image} variant="center" className="img-fluid" />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
