export const numberFormat = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatBytes = (bytes, decimals) => {
  if (bytes == 0) {
    return '0 Byte';
  }
  var k = 1024; //Or 1 kilo = 1000
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
};
