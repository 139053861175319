import React, { useState, useEffect } from 'react';
import { number, string } from 'prop-types';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';

/*
https://react-id-swiper.ashernguyen.site/
이걸로 변경

https://codepen.io/ncer/pen/xpqemZ
  
*/

import Loading from './Loading';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';

// slideApi
//import { SliderGitavApi } from '../api/slide-api';
//import { SliderApi } from '../../data/slide-api';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

const SliderWarp = styled(Container)`
  padding: 0;
  margin: 0;
  background-color: ${(props) => props['bgcolor']};
  transition: background 200ms ease 0s;
  transition-duration: 300ms;
  height: ${(props) => props['height']}px;
  margin-bottom: 120px;

  @media (max-width: 1199.98px) {
    height: 350px;
    margin-bottom: 150px;
  }
  @media (max-width: 991.98px) {
    height: 500px;
    margin-bottom: 200px;
  }
  @media (max-width: 767.98px) {
    height: 350px;
    margin-bottom: 250px;
  }
  @media (max-width: 576px) {
    height: 300px;
    margin-bottom: 40%;
  }
`;

const SliderContainer = styled(Swiper)`
  position: relative;
`;

const SlideInner = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  padding: 10px;
  padding-top: 50px;
  @media (max-width: 1399.98px) {
    padding-top: 50px;
  }
  @media (max-width: 1199.98px) {
    padding-top: 50px;
  }
  @media (max-width: 576px) {
    padding-top: 30px;
  }
`;
const SlideImg = styled(Col)`
  img {
  }
  @media (max-width: 991.98px) {
    .img-fluid {
      width: 100%;
    }
  }
  @media (max-width: 576px) {
    .img-fluid {
      width: 100%;
    }
  }
`;
const SlideText = styled(Col)`
  padding: 0 auto;
  margin: 0 auto;
  h1 {
    font-family: GmarketSansBold;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3.5rem;
  }
  h3 {
    font-family: GmarketSansMedium;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.4rem;
  }
  h5 {
    font-family: GmarketSansLight;
    margin-top: 30px;
    font-size: 1rem;
    font-weight: 100;
  }

  @media (max-width: 1199.98px) {
    margin-bottom: 30px;
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.4rem;
      font-weight: 300;
    }
    h5 {
      font-size: 1rem;
      font-weight: 300;
    }
  }
  @media (max-width: 991.98px) {
    margin-bottom: 30px;
    h1 {
      font-size: 2rem;
      line-height: 3rem;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 300;
    }
    h5 {
      font-size: 1rem;
      font-weight: 300;
    }
  }
  @media (max-width: 787.98px) {
    margin-bottom: 30px;
    h1 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.5rem;
    }
    h5 {
      font-size: 1rem;
      font-weight: 300;
    }
  }
  @media (max-width: 576px) {
    margin-bottom: 30px;
    h1 {
      font-size: 2.2rem;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.2rem;
    }
    h5 {
      margin-top: 20px;
      font-size: 1rem;
      font-weight: 300;
    }
  }
`;

const SwiperPrevButton = styled(Button)`
  width: 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 300px;
  right: 61px;
  z-index: 10;
  border: 1px solid #fff;
  border-radius: 0;

  @media (max-width: 1199.98px) {
    top: 270px;
  }
  @media (max-width: 991.98px) {
    top: 200px;
  }
  @media (max-width: 787.98px) {
    top: 150px;
  }
  @media (max-width: 576px) {
    top: 120px;
  }
`;

const SwiperNextButton = styled(Button)`
  width: 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 300px;
  right: 22px;
  z-index: 10;
  border: 1px solid #fff;
  border-radius: 0;
  transform: rotate(180deg);
  @media (max-width: 1199.98px) {
    top: 270px;
  }
  @media (max-width: 991.98px) {
    top: 200px;
  }
  @media (max-width: 787.98px) {
    top: 150px;
  }
  @media (max-width: 576px) {
    top: 120px;
  }
`;

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const MainSlider = ({ height, slidesPerView }) => {
  const [slideID, setslideID] = useState(0);
  const [items, setItems] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const SliderGitavApi = async () => {
    try {
      setError(null);
      setItems(null);
      setLoading(true);
      const { data: data } = await axios.get('/api/mainslide');
      setItems(data.list);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    SliderGitavApi();
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!items) return null;
  return (
    <SliderWarp fluid bgcolor={items[slideID].mb_bgcolor} height={height}>
      <Container className="p-0">
        <SliderContainer
          spaceBetween={100}
          slidesPerView={slidesPerView}
          loop
          navigation={{
            nextEl: '.swiper-button-next10',
            prevEl: '.swiper-button-prev10',
          }}
          /*
          pagination={{
            //el: ".swiper-pagination10",
            
            clickable: true,
          }}
          */
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={(e) => {
            setslideID(e.realIndex);
          }}
        >
          {items.map((data) => {
            return (
              <SwiperSlide key={data.mb_id}>
                <SlideInner>
                  <Row>
                    <SlideText
                      style={{
                        color: `${data.mb_color}`,
                      }}
                      md={12}
                      sm={12}
                      xs={12}
                      className="d-xl-none d-lg-none d-block"
                    >
                      <h1>{data.mb_title}</h1>
                      <h3>{data.mb_summary}</h3>
                      <h5>{data.mb_tag}</h5>
                    </SlideText>
                    <SlideImg xl={7} lg={8} md={12} sm={12} xs={12}>
                      <img src={data.mb_filename} className="img-fluid" />
                    </SlideImg>
                    <SlideText
                      style={{
                        color: `${data.mb_color}`,
                      }}
                      xl={5}
                      lg={4}
                      className="d-xl-block d-lg-block d-none"
                    >
                      <h1>{data.mb_title}</h1>
                      <h3>{data.mb_summary}</h3>
                      <h5>{data.mb_tag}</h5>
                    </SlideText>
                  </Row>
                </SlideInner>
              </SwiperSlide>
            );
          })}
          <SwiperPrevButton variant="outline-light" className="swiper-button-prev10 bg-transparent">
            <img src="/images/common/sl_l.svg" />
          </SwiperPrevButton>
          <SwiperNextButton variant="outline-light" className="swiper-button-next10 bg-transparent">
            <img src="/images/common/sl_l.svg" />
          </SwiperNextButton>
        </SliderContainer>
      </Container>
    </SliderWarp>
  );
};

MainSlider.propTypes = {
  height: number,
  slidesPerView: string,
};

export default MainSlider;
