import React, { useState, useEffect } from 'react';
import { string, bool, func } from 'prop-types';
//import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';

/* style sheet */
import { Warp } from '../style/default';
import Loading from '../../plugin/Loading';

/* util */
import { formatBytes } from '../../util/common';

const View = ({ viewOpen, seViewOpen, id }) => {
  const [item, setItem] = useState(null);
  const [files, setFiles] = useState(null);
  const [file_count, setFile_count] = useState(null);
  const [filedownload, setFiledownload] = useState(null);
  const [file_download_count, setFile_download_count] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [file_image_count, setFile_image_count] = useState(null);
  const [link, setLink] = useState(null);
  const [link_count, setLink_count] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const BoardViewApi = async () => {
    try {
      setError(null);
      setItem(null);
      setFiles(null);
      setFile_count(null);
      setFiledownload(null);
      setFile_download_count(null);
      setFileimage(null);
      setFile_image_count(null);
      setLink(null);
      setLink_count(null);
      setLoading(true);

      const { data } = await axios.get(`/api/post/${id}`);
      const { view } = data;
      const { config } = data;

      setItem(view.post);
      setFiles(view.file);
      setFile_count(view.file_count);
      setFiledownload(view.file_download);
      setFile_download_count(view.file_download_count);
      setFileimage(view.file_image);
      setFile_image_count(view.file_image_count);
      setLink(view.link);
      setLink_count(view.link_count);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    BoardViewApi();
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!item) return null;

  const { post_title, post_register_datetime, post_content, post_notice } = item;
  const content = post_content.split('\n').map((line, key) => {
    return (
      <div key={key} /* dangerouslySetInnerHTML={{ __html: line }}*/>
        {line}
        <br />
      </div>
    );
  });

  return (
    <>
      <Warp className="my-3">
        <Row>
          <Col>
            <Container>
              <Row className="view-box" style={{ padding: '15px 0' }}>
                <Col md={9} sm={12} xs={12}>
                  <ul
                    className="list-unstyled p-0 m-0 "
                    style={{ fontSize: '17px', fontWeight: '500' }}
                  >
                    {post_notice > 0 && (
                      <li
                        className="badge badge-success mr-2 float-left"
                        style={{ marginTop: '2px' }}
                      >
                        공지
                      </li>
                    )}
                    <li>{post_title}</li>
                  </ul>
                </Col>
                <Col md={3} sm={12} xs={12} className="text-right pr-2 d-none d-md-block">
                  {post_register_datetime}
                </Col>
                <Col xs={12} className="pl-2 d-md-none">
                  {post_register_datetime}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        {filedownload && (
          <Row>
            <Col>
              <Container>
                {filedownload.map((fdata) => {
                  const {
                    pfi_id,
                    pfi_filename,
                    pfi_originname,
                    pfi_filesize,
                    pfi_is_image,
                    download_link,
                  } = fdata;
                  const fileicon =
                    pfi_is_image > 0 ? '' : <Icon.FileEarmarkArrowDownFill className="mr-1" />;
                  return (
                    <Row key={pfi_id} style={{ padding: '5px 0' }}>
                      <Col className="p-1">
                        {fileicon}
                        <a href={download_link} className="fileLink" title={pfi_originname}>
                          {pfi_originname}
                        </a>
                        <span className="filesize ml-1">({formatBytes(pfi_filesize)})</span>
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </Col>
          </Row>
        )}

        {fileimage && (
          <Row>
            <Col>
              <Container className="view-file-box">
                {fileimage.map((fdata) => {
                  const {
                    pfi_id,
                    pfi_filename,
                    pfi_originname,
                    pfi_filesize,
                    pfi_is_image,
                    download_link,
                  } = fdata;
                  const fileicon =
                    pfi_is_image > 0 ? <Icon.FileEarmarkImage className="mr-1" /> : '';
                  return (
                    <Row key={pfi_id} style={{ padding: '5px 0' }}>
                      <Col
                        className="p-1"
                        style={{
                          fontSize: '14px',
                          fontWeight: '300',
                        }}
                      >
                        {fileicon}
                        <a href={download_link} className="fileLink" title={pfi_originname}>
                          {pfi_originname}
                        </a>
                        <span className="filesize ml-1">({formatBytes(pfi_filesize)})</span>
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </Col>
          </Row>
        )}

        {link_count > 0 && (
          <Row>
            <Col>
              <Container className="view-file-box">
                {link.map((fdata) => {
                  const { pln_id, pln_url, link_link } = fdata;
                  const fileicon = <Icon.Link45deg className="mr-1" />;
                  return (
                    <Row key={pln_id} style={{ padding: '5px 0' }}>
                      <Col xs={12} className="p-1">
                        {fileicon}
                        <a href={link_link} className="fileLink" target="_blank" title={pln_url}>
                          {pln_url}
                        </a>
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <Container>
              <Row>
                <Col
                  className="view-buttom-line p-2 py-4"
                  style={{
                    lineHeight: '1.7em',
                  }}
                >
                  {fileimage &&
                    fileimage.map((fdata, index) => {
                      const {
                        pfi_id,
                        pfi_filename,
                        pfi_originname,
                        pfi_filesize,
                        pfi_is_image,
                      } = fdata;
                      const imgcontent =
                        pfi_is_image > 0 ? (
                          <img src={`/assets/uploads/post/${pfi_filename}`} className="img-fluid" />
                        ) : (
                          ''
                        );
                      return (
                        <div key={index} className="col-xs-12 p-2">
                          {imgcontent}
                        </div>
                      );
                    })}
                  {content}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Warp>

      <Warp className="mb-5">
        <Row>
          <Col className="text-right">
            <Button
              size="sm"
              variant="dark"
              onClick={() => {
                window.scrollTo(0, 0);
                seViewOpen(!viewOpen);
              }}
            >
              목록
            </Button>
          </Col>
        </Row>
      </Warp>
    </>
  );
};

View.propTypes = {
  id: string.isRequired,
  viewOpen: bool.isRequired,
  seViewOpen: func.isRequired,
};
export default View;
