import React from "react";
import { Container, Row, Col } from "react-bootstrap";

/* 플러그인 호출 */
import KakaoMap from "../../plugin/KakaoMap";

/* style sheet */
import { Warp } from "../style/default";
import { DefaultSet } from "../style/contact";

export default function Contact() {
  return (
    <>
      <Warp>
        <Row>
          <Col
            className="mt-5 mb-3"
            style={{ fontFamily: "GmarketSansMedium", fontWeight: "600" }}
          >
            <h3>오시는길</h3>
          </Col>
        </Row>
      </Warp>

      <Container className="mb-5">
        <Row>
          <Col>
            <KakaoMap />
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <DefaultSet>
              <span className="title">오시는길</span>
              충북 청주시 서원구 산남로 70번길 17 2층
            </DefaultSet>
            <DefaultSet>
              <span className="title">문의 및 예약</span> 043-274-6177
            </DefaultSet>
          </Col>
        </Row>
      </Container>
    </>
  );
}
