import styled from 'styled-components';
import { Container, Card } from 'react-bootstrap';

export const Warp = styled(Container)`
  transition: background 200ms ease 0s;
  transition-duration: 300ms;
  transition: 0.5s;

  .filesize {
    font-size: 12px;
    font-weight: 300;
    color: #333;
    text-decoration: none;
  }

  a.fileLink {
    font-size: 12px;
    font-weight: 300;
    color: #333;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: #111;
    }
  }
`;

export const Imgover = styled(Card.Img)`
  width: 100%;
  height: 200px;
  transition-duration: 300ms;
  transform: scale(1, 1);
  transition: 0.2s;

  &:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
`;
