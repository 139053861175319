import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Container, Row, Col } from 'react-bootstrap';

export default function Loading() {
  return (
    <Container>
      <Row>
        <Col
          style={{
            display: 'flex',
            margin: '300px 0 300px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '3rem',
            height: '3rem',
          }}
        >
          <Spinner animation="grow" className="text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
}
