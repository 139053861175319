import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';

/* style sheet */
import { Warp, Imgover } from '../style/default';

import Loading from '../../plugin/Loading';

// slideApi
//import { IntroMenuApi } from '../../../data/intro-menu-api';

export default function Menu() {
  const [slideID, setslideID] = useState(0);
  const [items, setItems] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const SliderGitavApi = async () => {
    try {
      setError(null);
      setItems(null);
      setLoading(true);
      const { data: data } = await axios.get('/api/ordermenu');
      setItems(data.list);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    SliderGitavApi();
  }, []);

  console.log(items);

  if (loading) return <Loading />;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!items) return null;
  return (
    <>
      <Warp>
        <Row>
          <Col className="mt-5 mb-3" style={{ fontFamily: 'GmarketSansMedium', fontWeight: '600' }}>
            <h3>메뉴 안내</h3>
          </Col>
        </Row>
      </Warp>

      <Container className="mb-5">
        <Row>
          {items.map((data) => {
            return (
              <Col key={data.or_id} xs={12} sm={12} md={6} lg={4} className="mb-4">
                <Card>
                  <div style={{ width: '100%', overflow: 'hidden' }}>
                    <Imgover variant="center" src={data.or_filename} />
                  </div>
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'GmarketSansMedium' }}>
                      {data.or_title}
                    </Card.Title>
                    <Card.Text style={{ fontFamily: 'GmarketSansLight' }}>{data.or_tag}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}
