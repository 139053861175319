import styled from "styled-components";

export const DefaultSet = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  span {
    display: block;
    font-size: 12px;
    letter-spacing: -0.45px;
    line-height: 1.5;
    color: #929292;
    padding-top: 5px;
  }
  .title {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.46px;
    line-height: 22px;
    color: #2c2c2c;
    margin: 0 0 8px;
  }
  .content {
    font-weight: 700;
    font-size: 26px;
    letter-spacing: -0.8px;
    line-height: 1.46;
    color: #2c2c2c;
    a {
      color: #2c2c2c;
      text-decoration: none;
    }
    @media (max-width: 576px) {
      font-size: 24px;
    }
  }
`;
