import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import Loading from '../../plugin/Loading';
import Pagination from '../../plugin/Pagination';
import SearchForm from '../../plugin/SearchForm';

/* style sheet */
import { Warp } from '../style/default';

const BoardTable = styled(Table)`
  a {
    font-size: 13px;
    text-transform: uppercase;
    padding: 20px;

    font-weight: 400;
    color: #111;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1rem;
      text-align: center;
    }

    &:hover {
      color: #333;
    }
  }
  .fw400 {
    font-size: 13px;
    text-transform: uppercase;

    font-weight: 400;
    color: #111;
    text-decoration: none;
    transition: color 0.3s linear;
  }
  .fw500 {
    font-size: 13px;
    text-transform: uppercase;

    font-weight: 500;
    color: #111;
    text-decoration: none;
    transition: color 0.3s linear;
  }
  .fw600 {
    font-size: 13px;
    text-transform: uppercase;

    font-weight: 600;
    color: #111;
    text-decoration: none;
    transition: color 0.3s linear;
  }
`;

const List = ({ viewOpen, seViewOpen, setId }) => {
  const [total, setTotal] = useState(0);
  const [boardkey, setBoardkey] = useState(null);
  const [noticeitems, setNoticeitems] = useState(null);
  const [items, setItems] = useState(null);
  const [perpage, setPerpage] = useState(0);
  const [currentpage, setCurrentpage] = useState(1);
  const [sfield, setSfield] = useState(null);
  const [skeyword, setSkeyword] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let count = 0;

  const BoardGitavApi = async () => {
    try {
      setError(null);
      setItems(null);
      setBoardkey(null);
      setNoticeitems(null);
      setLoading(true);
      setTotal(0);
      setPerpage(0);
      const { data } = await axios.get(
        `/api/board/notice?page=${currentpage ? currentpage : 1}&sfield=${
          sfield !== null ? sfield : ''
        }&skeyword=${skeyword !== null ? skeyword : ''}`
      );
      const { view } = data;
      const { config } = data;
      setBoardkey(view.board_key);
      setItems(view.list.data);
      setNoticeitems(view.list.notice_list);
      setTotal(view.list.pagination.total_rows);
      setPerpage(view.list.pagination.per_page);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    BoardGitavApi();
  }, [currentpage, skeyword]);

  if (loading) return <Loading />;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!items && !total) return null;
  return (
    <>
      <Warp className="mb-1">
        <Row>
          <Col>
            <BoardTable responsive style={{ borderBottom: '1px solid #ddd' }}>
              <thead>
                <tr>
                  <th scope="col" style={{ textAlign: 'center' }}>
                    번호
                  </th>
                  <th scope="col" style={{ width: '60%', textAlign: 'center' }}>
                    제목
                  </th>
                  <th scope="col" style={{ textAlign: 'center' }}>
                    파일
                  </th>
                  <th scope="col" style={{ textAlign: 'center' }}>
                    날짜
                  </th>
                  <th scope="col" style={{ textAlign: 'center' }}>
                    조회
                  </th>
                </tr>
              </thead>
              <tbody>
                {noticeitems.map((e) => {
                  const fileicon = (e) => {
                    if (e.post_file > 0) {
                      return <Icon.FileEarmarkArrowDownFill className="mr-1" />;
                    } else if (e.post_image > 0) {
                      return <Icon.FileEarmarkImage className="mr-1" />;
                    } else {
                      return <Icon.FileEarmarkText className="mr-1" />;
                    }
                  };

                  return (
                    <tr key={e.post_id}>
                      <td style={{ textAlign: 'center', fontSize: '16px' }}>
                        <span className="badge badge-success">공지</span>
                      </td>
                      <td>
                        <Link
                          to="#"
                          onClick={() => {
                            seViewOpen(!viewOpen);
                            setId(e.post_id);
                          }}
                          className="fw500"
                        >
                          {e.post_title}
                        </Link>
                      </td>
                      <td className="fw500" style={{ textAlign: 'center', fontSize: '16px' }}>
                        {fileicon(e)}
                      </td>
                      <td className="fw500" style={{ textAlign: 'center' }}>
                        {e.display_datetime}
                      </td>
                      <td className="fw500" style={{ textAlign: 'center' }}>
                        {e.post_hit}
                      </td>
                    </tr>
                  );
                })}
                {items.list.map((e) => {
                  const fileicon = (e) => {
                    if (e.post_file > 0) {
                      return <Icon.FileEarmarkArrowDownFill className="mr-1" />;
                    } else if (e.post_image > 0) {
                      return <Icon.FileEarmarkImage className="mr-1" />;
                    } else {
                      return <Icon.FileEarmarkText className="mr-1" />;
                    }
                  };

                  return (
                    <tr key={e.post_id}>
                      <td className="fw400" style={{ textAlign: 'center' }}>
                        {e.num}
                      </td>
                      <td>
                        <Link
                          to="#"
                          onClick={() => {
                            seViewOpen(!viewOpen);
                            setId(e.post_id);
                          }}
                        >
                          {e.post_title}
                        </Link>
                      </td>
                      <td className="fw400" style={{ textAlign: 'center', fontSize: '16px' }}>
                        {fileicon(e)}
                      </td>
                      <td className="fw400" style={{ textAlign: 'center' }}>
                        {e.display_datetime}
                      </td>
                      <td className="fw400" style={{ textAlign: 'center' }}>
                        {e.post_hit}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </BoardTable>
          </Col>
        </Row>
      </Warp>

      <Warp>
        <Row className="justify-content-md-center mb-3">
          <Col xs="auto">
            <Pagination
              itemsCount={total}
              pageSize={perpage}
              currentpage={currentpage}
              setCurrentpage={setCurrentpage}
            />
          </Col>
        </Row>
      </Warp>

      <Warp className="mb-5">
        <Row className="justify-content-md-center">
          <Col xs="auto">
            <SearchForm
              sfield={sfield}
              setSfield={setSfield}
              skeyword={skeyword}
              setSkeyword={setSkeyword}
            />
          </Col>
        </Row>
      </Warp>
    </>
  );
};

export default List;
